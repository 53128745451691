import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import { useGetFlagByCandidateEmail } from 'lib/flag/getFlag';
import Panel from '../Panels';
import { titleize } from '../Reports/Report/lib/formatting';
import { FLAGR_KEYS } from '../../constants';

const MissingInfoPanel = ({
  documents,
  object,
  matrixFields,
  company,
  children,
  email,
}) => {
  documents = documents.filter(doc => Object.keys(doc).length !== 0);
  const determineMatrixVerification = () => {
    const CUSTOM_VERIFICATIONS = [
      'canada_police_information_check',
      'mvr_consent',
    ];

    const matrixVerificationName = matrixFields.find(matrixField => {
      return CUSTOM_VERIFICATIONS.includes(matrixField);
    });

    return matrixVerificationName || 'matrix_verification';
  };

  const mappedVerificationNames = {
    id_document_verification: 'id_verification',
    license_document_verification: 'id_verification',
    passport_document_verification: 'id_verification',
    previous_license_document_verification: 'id_verification',
    ssn_document_verification: 'id_verification',
    matrix_verification: determineMatrixVerification(),
  };

  let verificationName = mappedVerificationNames[object] || object;

  const requiresAdditionalContextText =
    verificationName === 'canada_police_information_check';

  const isPreviousDriverLicenseVerification =
    verificationName === 'driver_license_history_verification';

  const isSingleSsnCardVerification =
    verificationName === 'id_verification' &&
    documents.length === 1 &&
    documents[0].name === 'ssnCard';

  const ssnVerificationFlowV2 = isSingleSsnCardVerification;

  if (ssnVerificationFlowV2) {
    verificationName = 'ssn_document_verification';
  }

  const {
    flag: { variantKey: newSsnResolutionFlow },
  } = useGetFlagByCandidateEmail(FLAGR_KEYS.newSsnResolutionFlow, email);

  const isNewSsnResolutionFlow =
    newSsnResolutionFlow === 'on' && verificationName === 'ssn_check_failure';
  if (isNewSsnResolutionFlow) {
    verificationName = verificationName.replace(/^(new_)?/, 'new_');
  }

  if (verificationName === 'ssn_check_failure' && newSsnResolutionFlow === '') {
    return <M.LoadingBlock style={{ height: 130, borderRadius: 4 }} />;
  }
  return (
    <>
      <Panel
        i18n
        title={`components.Verification.MissingInfoPanel.${verificationName}.title`}
        className='warning panel--missing-info'
        icon='WarningFilled'
        isMastodon={isNewSsnResolutionFlow}
      >
        <T
          value={`components.Verification.MissingInfoPanel.${verificationName}.introText`}
          dangerousHTML
          company={titleize(company)}
        />
        {children && !(Array.isArray(children) && children.length === 0) && (
          <div className='text-center mt-4'>{children}</div>
        )}

        {documents?.length > 0 && !ssnVerificationFlowV2 && (
          <ul>
            {documents.map(
              (doc, i) =>
                doc.name && (
                  <li key={`li-${i + 1}`}>
                    <strong>
                      <T
                        value={`components.Verification.MissingInfoPanel.${verificationName}.${doc.name}`}
                      />
                    </strong>
                  </li>
                ),
            )}
            {isPreviousDriverLicenseVerification && (
              <li key='li-1'>
                <strong>
                  <T
                    value={`components.Verification.MissingInfoPanel.${verificationName}.alternateDoc`}
                    dangerousHTML
                  />
                </strong>
              </li>
            )}
          </ul>
        )}
      </Panel>

      {requiresAdditionalContextText && (
        <Panel
          i18n
          title={`components.Verification.MissingInfoPanel.${verificationName}.additionalContext.title`}
          className='card-plain card-border'
        >
          <T
            value={`components.Verification.MissingInfoPanel.${verificationName}.additionalContext.text`}
          />
        </Panel>
      )}
    </>
  );
};

MissingInfoPanel.propTypes = {
  company: PropTypes.string,
  documents: PropTypes.array,
  object: PropTypes.string,
  matrixFields: PropTypes.array,
  children: PropTypes.node,
  email: PropTypes.string,
};

MissingInfoPanel.defaultProps = {
  company: '',
  documents: [],
  object: '',
  matrixFields: [],
  children: [],
  email: '',
};

export default MissingInfoPanel;
