import React from 'react';
import styled from 'styled-components';
import '@dashboard-experience/mastodon/lib/styles.css';
import { M, colors } from '@dashboard-experience/mastodon';
import { Report } from 'types/report';
import ProgressBar from './progressBar';
import ReviewRecordsSection from './reviewRecords';

type Props = {
  report: Report;
};

const AccountName = styled.h1`
  color: ${colors.uiTextSecondaryLight};
  font-size: 30px;
  text-align: center;
  padding: 30px 0;
`;

const CandidateStoriesContainer: React.FC<Props> = ({ report }) => {
  const [currentStep, setCurrentStep] = React.useState(0);

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const {
    account: { name },
  } = report;

  const steps = [
    <ReviewRecordsSection
      key='review'
      report={report}
      handleNext={handleNext}
    />,
    <div key='explain'>PLACEHOLDER</div>,
  ];

  return (
    <M.Container data-testid='candidate-stories-container' type='transparent'>
      <AccountName data-testid='account-name'>{name}</AccountName>
      <ProgressBar currentStep={currentStep} />
      {steps[currentStep]}
    </M.Container>
  );
};

export default CandidateStoriesContainer;
