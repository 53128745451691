import { Report } from 'types/report';
import { considerScreenings } from 'components/Reports/Report/lib/screenings';

export const canEnableCandidateStories = (report: Report) => {
  const allConsiderScreenings = considerScreenings(report);
  const ssnTraceConsiderOnly =
    allConsiderScreenings.length === 1 &&
    allConsiderScreenings[0] === 'ssnTrace';

  if (ssnTraceConsiderOnly) {
    return false;
  }
  const { account } = report;

  let enabled = account.candidateStories && !!allConsiderScreenings.length;

  if (enabled && account.candidateStoriesAfterAdverseAction === false) {
    enabled = report.adjudication !== 'post_adverse_action';
  }

  if (enabled && account.candidateStoriesRequestRequired) {
    enabled = report.tags?.includes('candidate-story-requested');
  }

  return enabled;
};
