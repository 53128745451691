/* eslint-disable global-require */
import { i18n } from '@international/mastodon-i18n';
import {
  DISPUTE_SECTIONS,
  AUTO_DISPUTE_SCREENINGS,
} from '../../../../constants';
import { isInternational } from '../../../../lib/helpers';
import { dateIfDate } from './formatting';

const getDisputeTree = () => {
  const locale = i18n.getCookieLocale();
  const international = isInternational();

  const intSections =
    require(`../../../../data/international_disputes.json`).sections;
  const domesticSections = require(`../../../../data/disputes.json`).sections;

  // concat sections and remove duplicates
  let result = [...intSections, ...domesticSections].filter(
    (v, i, a) => a.findIndex(v2 => v2.name === v.name) === i,
  );

  if (international) {
    try {
      result =
        // eslint-disable-next-line import/no-dynamic-require
        require(`../../../../data/international_disputes_${locale}.json`).sections;
    } catch (e) {
      result = intSections;
    }
  }

  return result;
};

const ALL_SCREENINGS = [
  'ssnTrace',
  'evictionSearch',
  'sexOffenderSearch',
  'terroristWatchlistSearch',
  'healthcareSanctionsSearches',
  'internationalCriminalSearches',
  'arrestSearch',
  'nationalCriminalSearch',
  'stateCriminalSearches',
  'pointerStateCriminalSearches',
  'federalCriminalSearch',
  'federalDistrictCriminalSearches',
  'federalDistrictCivilSearches',
  'federalCivilSearch',
  'countyCriminalSearches',
  'countyCivilSearches',
  'clearinghouseSearch',
  'municipalCriminalSearches',
  'childAbuseSearches',
  'motorVehicleReport',
  'employmentVerification',
  'educationVerification',
  'personalReferenceVerifications',
  'professionalReferenceVerifications',
  'facisSearch',
  'creditReport',
  'professionalLicenseVerifications',
  'internationalGlobalWatchlistSearch',
  'internationalEducationVerification',
  'internationalEmploymentVerification',
  'internationalCriminalSearchesV2',
  'internationalCanadaBcEnhancedCriminalSearch',
  'internationalAdverseMediaSearches',
  'internationalIdentityDocumentValidation',
  'internationalMotorVehicleReports',
];

const validScreenings = report => {
  return ALL_SCREENINGS.filter(screening => {
    return (
      report[screening] &&
      (!!report[screening].length || !!report[screening].id)
    );
  });
};

const considerScreenings = report => {
  return validScreenings(report).filter(screening => {
    if (report[screening].length) {
      return report[screening].some(search => search.status === 'consider');
    }
    return report[screening].status === 'consider';
  });
};

const allScreenings = () => ALL_SCREENINGS;

const getSections = screenings => {
  const titles = screenings.map(screening =>
    i18n.getConstStr('disputes', DISPUTE_SECTIONS[screening]?.title),
  );
  return getDisputeTree().filter(section => titles.indexOf(section.name) >= 0);
};

const filterOutMvrScreening = screenings => {
  return screenings.filter(screening => screening !== 'motorVehicleReport');
};

const getDisputeSections = report => {
  let screenings = considerScreenings(report);
  screenings = filterOutMvrScreening(screenings);
  return getSections(screenings);
};

const getSection = sectionName => {
  return Object.keys(DISPUTE_SECTIONS).find(
    section =>
      sectionName ===
      i18n.getConstStr('disputes', DISPUTE_SECTIONS[section].title),
  );
};

const getSearches = (report, sectionNames) => {
  const sections = [];
  sectionNames.forEach(item => {
    const reportSection = report[getSection(item)];

    if (!reportSection) {
      return;
    }

    Array.isArray(reportSection)
      ? sections.push(...reportSection)
      : sections.push(reportSection);
  });
  return sections;
};

const getSearchesBySectionAndResult = (report, sections, result) => {
  return getSearches(report, sections).filter(
    search => search?.status === result,
  );
};

const requiresCharges = sectionName => {
  const disputeTree = getDisputeTree();
  const sectionObject = disputeTree.find(
    section => section.name === sectionName,
  );
  const sectionLabels = sectionObject ? sectionObject.labels : [];

  return sectionLabels.some(label => label.indexOf('charges') !== -1);
};

const getArticles = sectionName => {
  if (!sectionName) return [];

  return getDisputeTree().find(section => section.name === sectionName)
    ?.articles;
};

const getArticle = (sectionName, articleName) => {
  if (!sectionName || !articleName) return {};

  const articles = getArticles(sectionName);
  return articles.find(article => article.name === articleName) || {};
};

const requiresTicket = (sectionName, articleName) => {
  const article = getArticle(sectionName, articleName);
  return (article.labels ?? []).some(label => label.indexOf('reason_') !== -1);
};

const requiresField = (sectionName, articleName) => {
  const article = getArticle(sectionName, articleName);

  return (article.labels ?? []).some(
    label => label.indexOf('field_required') !== -1,
  );
};

const allSearchRecords = search => {
  return search?.records.concat(
    search?.filteredByPositiveAdjudicationRecords || [],
  );
};

const getFieldOptions = sectionName => {
  switch (sectionName) {
    case 'Arrest Search':
      return ['Charge Name', 'Booking Information'];
    default:
      return ['Charge Name', 'Charge Type', 'Dates', 'Disposition', 'Sentence'];
  }
};

export const getFirstRecordCharge = record => {
  const allCharges = record.charges.concat(
    record.filteredByPositiveAdjudicationCharges || [],
    record.chargesHiddenByAssess || [],
  );
  return allCharges[0];
};

const formatCountry = country => {
  if (!country) return 'N/A';

  return `${i18n.getCountry(country)} (${country})`;
};

const formatDistrictSearches = districtSearches => {
  if (!districtSearches) return 'N/A';

  const districts = districtSearches.map(item => item.courtJurisdiction);

  return districts.filter(n => n).join(', ');
};

const formatSearchTitle = search => {
  const { court, county, state, districtSearches, country } = search;

  if (country) {
    return formatCountry(country);
  }

  if (districtSearches) {
    return formatDistrictSearches(districtSearches);
  }

  return [court, county, state].filter(n => n).join(', ');
};

const getChargeTitle = record => {
  const firstCharge = getFirstRecordCharge(record);
  return firstCharge ? firstCharge.charge : 'Unknown charge';
};

const getChargeDate = record => {
  const firstCharge = getFirstRecordCharge(record);
  return firstCharge ? dateIfDate(firstCharge.offenseDate) : null;
};

const generateDisputeReason = (article, section) => {
  const articleObj = getArticle(section, article);

  return articleObj.labels.find(
    labelParam => labelParam.indexOf('reason_') !== -1,
  );
};
const isInfoScreen = (sectionName, articleName) => {
  const article = getArticle(sectionName, articleName);
  return (article.labels ?? []).some(label =>
    label.includes('information_screen'),
  );
};

const isAutoDisputeScreening = currentSection => {
  return AUTO_DISPUTE_SCREENINGS.some(
    screening => screening === currentSection,
  );
};

const getMotorVehicleRecordLengths = licenseReport => {
  if (licenseReport) {
    const fields = [
      'restrictions',
      'violations',
      'accidents',
      'suspensions',
      'endorsements',
    ];

    return fields.reduce((results, field) => {
      const key = `${field}Length`;
      return {
        ...results,
        [key]: licenseReport[field]?.length || 0,
      };
    }, {});
  }
  return {};
};

export {
  allSearchRecords,
  validScreenings,
  considerScreenings,
  filterOutMvrScreening,
  getSections,
  getDisputeSections,
  getArticles,
  getArticle,
  getMotorVehicleRecordLengths,
  allScreenings,
  getSection,
  getSearches,
  getSearchesBySectionAndResult,
  formatCountry,
  formatSearchTitle,
  getChargeTitle,
  getChargeDate,
  requiresCharges,
  requiresField,
  getFieldOptions,
  requiresTicket,
  generateDisputeReason,
  isInfoScreen,
  isAutoDisputeScreening,
};
