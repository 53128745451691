import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { spaceToUnderscore } from '@dashboard-experience/utils';
import { getFirstRecordCharge } from 'components/Reports/Report/lib/screenings';
import { formatDateIfValid } from 'lib/helpers';
import { getAgeAtDate, timeSinceDate } from 'lib/dateHelpers';

type Props = {
  dob: string;
  record: any;
};

const RecordContainer = styled(M.Container)`
  width: 516px;
`;

const defaultStr = 'Unknown';

const ChargeKeyValue = (k: string, v: string, id: string) => {
  const stringToId = (s: string) => {
    return spaceToUnderscore(s.toLowerCase());
  };

  const key = (
    <span key={`charge-key-${id}`} data-testid={`charge-key-${stringToId(k)}`}>
      {k}
    </span>
  );
  const value = (
    <span
      key={`charge-value-${id}`}
      data-testid={`charge-value-${stringToId(k)}`}
    >
      {v || 'Unknown'}
    </span>
  );
  return { itemKey: key, itemValue: value };
};

const Record: React.FC<Props> = ({ dob, record }) => {
  const charge = getFirstRecordCharge(record);
  const {
    charge: chargeTitle,
    chargeType,
    disposition,
    dispositionDate,
    offenseDate,
  } = charge;

  const validDispositionDate = dispositionDate
    ? formatDateIfValid(dispositionDate)
    : '';

  const items = [
    ChargeKeyValue('Charge', chargeTitle, record.id),
    ChargeKeyValue('Charge type', chargeType, record.id),
    ChargeKeyValue('Disposition', disposition, record.id),
    ChargeKeyValue('Disposition date', validDispositionDate, record.id),
    ChargeKeyValue(
      'Age at offense',
      getAgeAtDate(dob, offenseDate) || defaultStr,
      record.id,
    ),
    ChargeKeyValue(
      'Time since offense',
      timeSinceDate(offenseDate) || defaultStr,
      record.id,
    ),
  ];

  return (
    <RecordContainer
      data-testid='candidate-stories-record'
      type='official'
      rows={[
        <h3
          data-testId='candidate-stories-record-title'
          key={`record-title-${record.id}`}
        >
          {chargeTitle}
        </h3>,
        <M.KeyValueList
          key={`record-items-${record.id}`}
          items={items.filter(i => i)}
        />,
      ]}
    />
  );
};

export default Record;
