import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import I18nLink from 'components/I18nLink/I18nLink';
import { clearFetchReportsFailure } from 'actions';
import ReCAPTCHA from 'react-google-recaptcha';
import { ReduxStore } from 'providers/types';
import {
  CHECKR_APPLICANTS_EU_BASE_URL,
  RECAPTCHA_SITE_KEY,
} from '../../../../constants';
import * as V from '../../../../lib/validations';
import * as O from '../../../../lib/options';
import { Input } from '../../../fields';
import DobSelect from './DobSelect';
import { CandidateLoginFormValues, validate } from './validators';

const StyledI18Link = styled(I18nLink)`
  font-size: 16px;
  line-height: 24px;
  .cds--link--lg {
    font-size: unset;
  }
`;

const LOGIN_FORM_NAME = 'candidateLoginForm';

interface CandidateLoginFormProps
  extends InjectedFormProps<CandidateLoginFormValues> {
  errorMessage: string;
  processing: boolean;
  handleSubmit: () => void;
}

export const CandidateLoginForm: React.FC<CandidateLoginFormProps> = ({
  processing,
  handleSubmit,
  errorMessage,
  error,
  change,
}) => {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = React.useState(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleClickSubmit = async (e: React.ChangeEvent) => {
    e.preventDefault();

    try {
      const recaptchaCode = await recaptchaRef.current?.executeAsync();
      change('recaptchaCode', recaptchaCode);
      handleSubmit();
    } catch (recaptchaError) {
      // eslint-disable-next-line no-console
      console.error('ReCAPTCHA error: ', recaptchaError);
    } finally {
      change('recaptchaCode', '');
      recaptchaRef.current?.reset();
    }
  };

  React.useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <div id='passwordless-login' data-testid='passwordless-login'>
      <M.Grid>
        {errorMessage && (
          <M.GridRow>
            <M.InlineNotification
              kind='error'
              title={i18n.getStr('components.candidateAuthEmail.errorTitle')}
              subtitle={i18n.getStr(
                `components.candidateAuthEmail.errors.${errorMessage}`,
              )}
              className='error-message'
            />
          </M.GridRow>
        )}

        <M.GridRow>
          <M.GridCol className='email-auth-body'>
            <T
              value='components.candidateAuthEmail.welcomeText'
              dangerousHTML
            />
            <StyledI18Link
              linkTarget='_self'
              translationKey='components.candidateAuthEmail.internationalLink'
              renderHTML
              to={`${CHECKR_APPLICANTS_EU_BASE_URL}/international`}
            />
          </M.GridCol>
        </M.GridRow>
      </M.Grid>

      <M.Form>
        <M.Grid>
          <M.GridRow>
            <M.GridCol>
              <label htmlFor='dob' id='dob' className='cds--label'>
                {i18n.getStr(`components.candidateAuthEmail.dobLabel`)}
              </label>
            </M.GridCol>
          </M.GridRow>
          <DobSelect
            fields={[
              { name: 'dob.month', options: O.months() },
              { name: 'dob.day', options: O.days() },
              { name: 'dob.year', options: O.years(14, 100) },
            ]}
            disabled={!loaded}
            validate={[V.required]}
            error={error || ''}
          />
          <M.GridRow>
            <M.GridCol>
              <Field
                type='email'
                name='email'
                label='labels.email'
                placeholder='placeholders.email'
                component={Input}
                validate={[V.required, V.emailFormat]}
                helperText={i18n.getStr(
                  'components.candidateAuthEmail.formText',
                )}
              />
            </M.GridCol>
          </M.GridRow>
          <M.GridRow>
            <M.GridCol>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={RECAPTCHA_SITE_KEY}
                size='invisible'
                badge='inline'
                hl={i18n.getGoogleRecaptchaLocale()}
                onErrored={() => {
                  // eslint-disable-next-line no-console
                  console.error('ReCAPTCHA error');
                }}
              />
            </M.GridCol>
          </M.GridRow>
          <M.GridRow>
            <M.GridCol>
              <M.Button
                type='submit'
                kind='primary'
                size='md'
                className='btn btn-lg btn-block full'
                disabled={processing}
                onClick={handleClickSubmit}
              >
                {processing || !loaded ? (
                  <M.LoadingInline className='button-loading-icon' />
                ) : (
                  <T value='components.candidateAuthEmail.submitButton' />
                )}
              </M.Button>
            </M.GridCol>
          </M.GridRow>
        </M.Grid>
      </M.Form>

      <M.Grid className='login-info'>
        <M.GridRow>
          <Link
            to='/login'
            data-testid='pii-auth-link'
            onClick={e => {
              dispatch(clearFetchReportsFailure());
            }}
          >
            <T value='components.candidateAuthEmail.redirectLogin' />
          </Link>
        </M.GridRow>
      </M.Grid>
    </div>
  );
};

CandidateLoginForm.propTypes = {
  processing: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state: ReduxStore) => {
  const { submitError, processing, errorMessage } = state.candidateLogin;
  return {
    submitError,
    processing,
    errorMessage,
  };
};

const ReduxCandidateLoginForm = reduxForm<
  CandidateLoginFormValues,
  CandidateLoginFormProps
>({
  form: LOGIN_FORM_NAME,
  validate,
  shouldValidate: () => true,
})(CandidateLoginForm);

export default connect(mapStateToProps)(
  i18n.renderTranslation()(ReduxCandidateLoginForm),
);
