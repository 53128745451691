import { FormErrors } from 'redux-form';
import { moment } from '@international/mastodon-i18n';

export interface CandidateLoginFormValues {
  phone: string;
  email: string;
  ssn: string;
  dob: {
    month: {
      selectedItem: string;
    };
    day: { selectedItem: string };
    year: { selectedItem: string };
  };
  recaptchaCode: string;
}

export const validate = (values: CandidateLoginFormValues): FormErrors => {
  const errors: FormErrors = {};
  const { month, day, year } = values.dob || {};

  if (!month || !day || !year) return {};

  const formValue = month.selectedItem;
  const monthStr = formValue.split(' ')[0];
  const dayStr = day.selectedItem;
  const yearStr = year.selectedItem;

  const date = `${monthStr}/${dayStr}/${yearStr}`;
  if (!moment(date, 'M/D/YYYY', false).isValid()) {
    // eslint-disable-next-line no-underscore-dangle
    errors._error = 'components.candidateAuthEmail.validations.invalidDate';
  }

  return errors;
};
