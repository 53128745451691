import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import { useGetFlagByCandidateEmail } from 'lib/flag/getFlag';
import IconPanel from '../IconPanel';
import Login from '../fields/LoginButton';

import completeIcon from '../../images/icons/Complete.svg';
import { FLAGR_KEYS } from '../../constants';
import IconPanel2 from '../IconPanel2';
import './completed.scss';

const Completed: React.FC<{
  candidateEmail: string;
  matrixFields?: string[];
}> = ({ matrixFields, candidateEmail }) => {
  const {
    flag: { variantKey: newSsnResolutionFlow },
  } = useGetFlagByCandidateEmail(
    FLAGR_KEYS.newSsnResolutionFlow,
    candidateEmail,
  );

  if (newSsnResolutionFlow === '') return null;
  const isNewSsnResolutionFlow = newSsnResolutionFlow === 'on';
  return (
    <div>
      {isNewSsnResolutionFlow ? (
        <IconPanel2
          translationKey='components.Verification.NewCompleted'
          icon={
            <M.Icon
              icon='CheckmarkOutline'
              style={{ fill: '#009BB5', width: 40, height: 40 }}
            />
          }
        />
      ) : (
        <IconPanel
          translationKey='components.Verification.Completed'
          icon={
            <img
              src={completeIcon}
              alt={i18n.getStr('components.Verification.Completed.title')}
            />
          }
          params={{ matrixFields }}
        >
          <div className='text-center'>
            <Login />
          </div>
        </IconPanel>
      )}
    </div>
  );
};

export default Completed;
