import React from 'react';
import {
  Navigate,
  Routes,
  Route,
  useParams,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isInternational as getIsInternational } from 'lib/helpers';
import { getLoginSource } from 'containers/ReportsOverview/lib/getLoginSource';
import { ReportPage } from 'components/ReportPage/ReportPage';
import Auth from 'containers/Auth';
import { useReport } from 'api/report/useReport';
import useToken from 'utils/useToken';
import { useGetFlagByCandidateEmail } from 'lib/flag/getFlag';
import NotFound from 'routes/pages/NotFound';
import { AuthenticatedView } from '../ReportsOverview/AuthenticatedView';
import { FLAGR_KEYS, PATHS } from '../../constants';
import CandidateStoriesPage from './CandidateStories';

const getReduxState = (state: any) => {
  const { portal, international } = state;
  return {
    portal,
    international:
      getIsInternational() || international?.international || false,
  };
};

type ParamsType = { reportId: string };

const ReportContainer: React.FC = () => {
  const location = useLocation();
  const { accountName, displayStatus, contactCompanyForReport } =
    location?.state || {};
  const { international } = useSelector(getReduxState);
  const loginSource = getLoginSource(international);
  const { reportId } = useParams() as ParamsType;
  const token = useToken();
  const { report, isLoading, isError } = useReport({
    id: reportId,
    token,
    contactCompanyForReport,
  });

  const {
    flag: { variantKey: newSsnResolutionFlow },
  } = useGetFlagByCandidateEmail(
    FLAGR_KEYS.newSsnResolutionFlow,
    report?.candidate?.email,
  );

  if (isError)
    return <Navigate to={international ? PATHS.INTERNATIONAL : '/'} />;

  return (
    <Auth
      loginSource={loginSource}
      international={international}
      token={token}
      isLoading={isLoading || newSsnResolutionFlow === ''}
    >
      <AuthenticatedView>
        <Routes>
          <Route
            path='/'
            element={
              <ReportPage
                report={
                  report || {
                    account: { name: accountName },
                    brandingName: '',
                    displayStatus,
                    id: reportId,
                    candidate: { email: '' },
                    countyCriminalSearches: [],
                  }
                }
                international={international}
                isLoading={isLoading}
                contactCompanyForReport={contactCompanyForReport}
              />
            }
          />
          <Route
            path='candidate_stories'
            element={<CandidateStoriesPage report={report} />}
          />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </AuthenticatedView>
    </Auth>
  );
};

export default ReportContainer;
