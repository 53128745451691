import moment from 'moment';

export const calculateDateDiff = (
  startDate: moment.Moment | string | null,
  endDate: moment.Moment | string | null,
) => {
  const start = moment(startDate, 'YYYY-MM-DD');
  const end = moment(endDate, 'YYYY-MM-DD');
  let years = null;
  let months = null;

  if (start.isValid() && end.isValid()) {
    years = end.diff(start, 'year');
    start.add(years, 'years');
    months = end.diff(start, 'months');
  }

  return { years, months };
};

export const getAgeAtDate = (dob: string | null, date: string | null) => {
  if (!date || !dob) return null;
  const age = moment(date).diff(dob, 'years');
  return `${age} years old`;
};

export const timeSinceDate = (date: string | null) => {
  if (!date) return null;

  const now = moment();
  const { years, months } = calculateDateDiff(date, now);

  if (years == null || months == null || years < 0 || months < 0) return null;
  if (years === 0 && months === 0) {
    return 'less than one month';
  }
  if (years === 0) {
    return `${months} months`;
  }
  return `${years} year(s), ${months} month(s)`;
};
