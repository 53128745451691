export const validateFormat = (value, format, error) => {
  if (!value || value === '') {
    return;
  }

  if (!format.test(value)) {
    // eslint-disable-next-line consistent-return
    return error;
  }
};

export const validateHasNoSsn = values => {
  const { noSsn } = values;
  let hasNoSsn = false;

  if (noSsn) {
    return true;
  }

  for (const value of Object.values(values)) {
    if (typeof value === 'object' && value !== null) {
      if ('noSsn' in value && value.noSsn) {
        return true;
      }

      hasNoSsn = validateHasNoSsn(value);
      if (hasNoSsn) return true;
    }
  }

  return hasNoSsn;
};
