import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import { STEPS } from './helpers';
import './styles.scss';

type PropsType = {
  currentStep?: number;
};

const ProgressBar: React.FC<PropsType> = ({ currentStep = 0 }) => {
  const steps = Object.values(STEPS).map(step => {
    const label = (
      <T value={`components.Report.CandidateStories.steps.${step}.title`} />
    );

    return (
      <M.ProgressStep
        key={step}
        label={label}
        data-testid={`progress-bar-step-${step}`}
      />
    );
  });

  return (
    <M.ProgressIndicator
      currentIndex={currentStep}
      data-testid='candidate-stories-progress-bar'
      spaceEqually
      vertical={false}
    >
      {steps}
    </M.ProgressIndicator>
  );
};

export default ProgressBar;
