import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { i18n } from '@international/mastodon-i18n';

import { useGetFlagByCandidateEmail } from 'lib/flag/getFlag';
import { FLAGR_KEYS } from '../../constants';
import { addCustomIncident, trackAnalyticsEvent } from '../../actions';
import { generateCustomIncidentsContentKey } from '../Reports/Report/lib/incidents';
import MainContainer from '../MainContainer';
import { Navigation } from './Navigation';
import ReportContainer from '../Reports/Report/Report';
import { Report as ReportInterface } from '../../types/report';
import { ANALYTICS_EVENTS } from '../../lib/analytics';

import './ReportPage.scss';

type Report =
  | ReportInterface
  | {
      account: { name: string };
      brandingName: string;
      displayStatus: string;
      id: string;
      candidate: { email: string } | undefined;
      countyCriminalSearches: Array<any>;
    };

interface ReportPageProps {
  report: Report;
  isLoading: boolean;
  international: boolean;
  expungements?: Array<any>;
  contactCompanyForReport?: boolean;
}

export const ReportPage: React.FC<ReportPageProps> = ({
  report,
  isLoading,
  international,
  expungements = [],
  contactCompanyForReport = false,
}) => {
  const {
    flag: { variantKey: newSsnResolutionFlow },
  } = useGetFlagByCandidateEmail(
    FLAGR_KEYS.newSsnResolutionFlow,
    report?.candidate?.email,
  );
  const isNewSsnResolutionFlow = newSsnResolutionFlow === 'on';

  const {
    flag: { variantAttachment: countyVariantAttachment },
    isLoading: countyFlagIsLoading,
  } = useGetFlagByCandidateEmail(
    FLAGR_KEYS.showCountyDelayMessage,
    report?.candidate?.email,
  );

  const expungement = expungements.find(({ reportId: id }) => id === report.id);

  const dispatch = useDispatch();
  const reportId = report.id;
  const [sentAnalytics, setSentAnalytics] = useState(false);
  useEffect(() => {
    if (!isLoading && report && !sentAnalytics) {
      setSentAnalytics(true);
      dispatch(trackAnalyticsEvent(ANALYTICS_EVENTS.REPORT_VIEWED));
    }
  }, [isLoading, dispatch, report, sentAnalytics]);
  useEffect(() => {
    const customIncidentContextChecks = generateCustomIncidentsContentKey(
      report as ReportInterface,
    );

    const customIncidentContentKeys = customIncidentContextChecks
      .filter(({ context: reportContext }) => {
        return countyVariantAttachment?.counties?.some(
          ({ county, state }: { county: string; state: string }) => {
            return (
              county.toLowerCase() ===
                (reportContext as { county: string }).county &&
              state.toLowerCase() === (reportContext as { state: string }).state
            );
          },
        );
      })
      .map(({ contentKey }) => contentKey);

    if (reportId && customIncidentContentKeys.length > 0) {
      dispatch(addCustomIncident(customIncidentContentKeys));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, countyFlagIsLoading, countyVariantAttachment]);

  if (isLoading) return null;

  return (
    <div>
      <MainContainer
        className={`report__wrapper${
          isNewSsnResolutionFlow ? ' report__wrapper--new-flow' : ''
        }`}
        header={
          <Navigation
            text={i18n.getStr('components.Report.homeButton')}
            isInternational={international}
            className={isNewSsnResolutionFlow ? '' : '--prev-view'}
          />
        }
      >
        {report?.account?.name && (
          <div
            data-testid='report-title'
            className={`report__header${
              isNewSsnResolutionFlow ? ' report__header--status' : ''
            }`}
          >
            <h1
              className={`report--account-name${
                isNewSsnResolutionFlow ? '' : ' --prev-view'
              }`}
            >
              {report?.brandingName || report?.account?.name}
            </h1>
            {report.displayStatus && isNewSsnResolutionFlow ? (
              <p className='report--status' data-testid='report-title-status'>
                <b>{i18n.getStr('components.Report.Header.status.title')}:</b>{' '}
                {i18n.getStr(
                  `components.Report.Header.status.${report.displayStatus}`,
                )}
              </p>
            ) : null}
          </div>
        )}
        {contactCompanyForReport ? (
          <div>
            <h2>
              To access your report please contact{' '}
              <a
                href='https://help.checkr.com/s/contactsupport'
                target='_blank'
                rel='noopener noreferrer'
              >
                Checkr&apos;s Help Center
              </a>
              .
            </h2>
          </div>
        ) : (
          report && (
            <ReportContainer
              report={report}
              international={international}
              expungement={expungement}
            />
          )
        )}
      </MainContainer>
    </div>
  );
};
