import React from 'react';
import styled from 'styled-components';
import '@dashboard-experience/mastodon/lib/styles.css';
import { M, colors } from '@dashboard-experience/mastodon';
import { Report } from 'types/report';
import { Link } from 'react-router-dom';

type Props = {
  report: Report;
  title: string;
  description: React.ReactNode;
  children: React.ReactNode;
};

const Title = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
`;

const ReportButton = styled(M.Button)`
  height: 36px;
`;

const DescriptionText = styled.p`
  color: ${colors.uiTextTertiaryLight};
  font-weight: 400;
`;

const StepContainer: React.FC<Props> = ({
  report,
  title,
  description,
  children,
  ...props
}) => {
  const reportLink = `/reports/${report.id}`;

  return (
    <div data-testid='candidate-stories-step-container' {...props}>
      <Title>
        <div>
          <h1>{title}</h1>
          <DescriptionText className='p2'>{description}</DescriptionText>
        </div>
        <Link data-testid='report-page-link' to={reportLink}>
          <ReportButton kind='tertiary' size='lg'>
            Open your report
            <M.Icon icon='ArrowUpRight' />
          </ReportButton>
        </Link>
      </Title>
      {children}
    </div>
  );
};

export default StepContainer;
