import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { M } from '@dashboard-experience/mastodon';

import '../scss/Footer.scss';
import { trackAnalyticsEvent as trackAnalyticsEventAction } from '../actions';
import { ANALYTICS_EVENTS } from '../lib/analytics';

const Footer = ({ international = false, trackAnalyticsEvent }) => {
  return (
    <>
      <footer className='footer text-center' data-testid='privacy-policy-link'>
        {i18n.renderHTML('components.Footer.privacyPolicyLink')}
        <div
          style={{
            margin: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <a
            data-testid='help-center-footer-link'
            href={i18n.getStr('components.Footer.checkrHelpCenterUrl')}
            target='_blank'
            rel='noopener noreferrer'
            onClick={() =>
              trackAnalyticsEvent(ANALYTICS_EVENTS.HELP_CENTER_VIEWED)
            }
          >
            <T
              value='components.Footer.helpCenter'
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                textDecoration: 'none',
              }}
            />
          </a>
          &nbsp;
          <svg
            width='1'
            height='20'
            fill='#E1E6EB'
            style={{ textAlign: 'center' }}
          >
            <rect width='1' height='20' />
          </svg>
          <a
            href={i18n.getStr('components.Footer.checkrTermsOfServiceUrl')}
            target='_blank'
            rel='noopener noreferrer'
            data-testid='terms-of-service-link'
          >
            <T
              value='components.Footer.termsOfService'
              style={{ textAlign: 'center' }}
            />
          </a>
        </div>
        <M.Divider style={{ width: '100%', margin: '8px 0px' }} />
        <div>
          <T value='address' dangerousHTML />
        </div>
      </footer>
    </>
  );
};

Footer.propTypes = {
  international: PropTypes.bool.isRequired,
  trackAnalyticsEvent: PropTypes.func,
};

Footer.defaultProps = {
  trackAnalyticsEvent: () => {},
};

export default i18n.renderTranslation()(
  connect(null, {
    trackAnalyticsEvent: trackAnalyticsEventAction,
  })(Footer),
);
