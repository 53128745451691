import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import IdDocumentVerification from 'components/Verification/IdDocumentVerification/IdDocumentVerification';
import Auth from 'containers/Auth';
import SsnCheckVerification from 'components/Verification/SsnCheckVerification/SsnCheckVerification';
import { useGetFlagByCandidateEmail } from 'lib/flag/getFlag';
import MainContainer from '../../components/MainContainer';
import Loading from '../../components/Loading';
import ResourceNotFound from '../ResourceNotFound';
import classify from '../../lib/classify';
import CandidateEmploymentHistoryVerification from '../../components/Verification/CandidateEmploymentHistoryVerification';
import EducationVerification from '../../components/Verification/EducationVerification';
import IdVerification from '../../components/Verification/IdVerification';
import PhotoVerification from '../../components/Verification/PhotoVerification';
import DriverLicenseVerification from '../../components/Verification/DriverLicenseVerification';
import SsnCheck from '../../components/Verification/SsnCheck';
import MatrixVerification from '../../components/Verification/MatrixVerification';
import {
  fetchMockVerification as fetchMockVerificationAction,
  fetchVerification as fetchVerificationAction,
  submitEducationVerification as submitEducationVerificationAction,
  submitEmploymentVerification as submitEmploymentVerificationAction,
  submitMatrixVerification as submitMatrixVerificationAction,
  submitSSNVerificationWithoutDocs as submitSSNVerificationWithoutDocsAction,
  submitVerificationDefault as submitVerificationDefaultAction,
} from '../../actions';
import { ENV, FLAGR_KEYS } from '../../constants';
import './Status.scss';

export interface StatusProps {
  fetchVerification: (params: any) => Promise<void>;
  submitEducationVerification: () => void;
  submitEmploymentVerification: () => void;
  submitMatrixVerification: () => Promise<void>;
  submitVerificationDefault: () => void;
  submitSSNVerificationWithoutDocs: () => Promise<void>;
  verifications: {
    verification: Record<string, any>;
    fetching: boolean;
    steps: any[];
    errors: any[] | null;
  };
}

type DispatchVerification = ({
  verificationId,
}: {
  verificationId: any;
}) => (dispatch: any) => Promise<any>;

const ComponentMapping: Record<string, any> = {
  CandidateEmploymentHistoryVerification,
  EducationVerification,
  PhotoVerification,
  DriverLicenseVerification,
  DriverLicenseHistoryVerification: DriverLicenseVerification,
  SsnCheck,
  SsnCheckFailure: SsnCheck,
  IdDocumentVerification: IdVerification,
  IdVerification,
  LicenseDocumentVerification: IdVerification,
  PassportDocumentVerification: IdVerification,
  PreviousLicenseDocumentVerification: IdVerification,
  SsnDocumentVerification: IdVerification,
  MatrixVerification,
};

const VerificationComponentMapping: Record<string, any> = {
  ...ComponentMapping,
  SsnCheck: SsnCheckVerification,
};

const IdVerificationComponentMapping: Record<string, any> = {
  ...VerificationComponentMapping,
  IdVerification: IdDocumentVerification,
};

const getVerificationComponent = (verification: Record<string, any>) => {
  return IdVerificationComponentMapping[classify(verification.object)];
};

export const Status: React.FC<StatusProps> = ({
  submitEducationVerification,
  submitEmploymentVerification,
  submitSSNVerificationWithoutDocs,
  submitVerificationDefault,
  submitMatrixVerification,
  verifications: { verification, fetching, errors },
  fetchVerification,
}) => {
  const params = useParams();
  const verificationId = params?.verificationId
    ?.replace(/[^a-fA-F0-9]/g, '')
    .slice(0, 24);
  const {
    flag: { variantKey: newSsnResolutionFlow },
  } = useGetFlagByCandidateEmail(
    FLAGR_KEYS.newSsnResolutionFlow,
    verification?.email,
  );

  React.useEffect(() => {
    const pageTitle = i18n.getStr('containers.page.title');
    const verificationWords = verification
      ? verification.object.split('_')
      : [];
    const verificationStart = verificationWords.length
      ? verificationWords[0].charAt(0).toUpperCase() +
        verificationWords[0].slice(1).toLowerCase()
      : '';
    const verificationEnd = verificationWords.slice(1).join(' ');

    document.title = verificationStart
      ? `${pageTitle} - ${verificationStart} ${verificationEnd}`
      : pageTitle;
  }, [verification]);
  React.useEffect(() => {
    if (!errors && !fetching && !(verification?.id === verificationId)) {
      fetchVerification(verificationId);
    }
  }, [fetching, verification, fetchVerification, verificationId, errors]);

  const submitVerification = (verificationType: string) => {
    const customSubmitFunctions: Record<string, any> = {
      education_verification: submitEducationVerification,
      candidate_employment_history_verification: submitEmploymentVerification,
      ssn_check: submitSSNVerificationWithoutDocs,
      matrix_verification: submitMatrixVerification,
    };

    return customSubmitFunctions[verificationType] || submitVerificationDefault;
  };

  if (fetching || newSsnResolutionFlow === '') {
    return (
      <MainContainer data-testid='verification-page'>
        <Loading />
      </MainContainer>
    );
  }

  if (!verification || errors) {
    return (
      <MainContainer data-testid='verification-page'>
        <ResourceNotFound />
      </MainContainer>
    );
  }
  const isNewSsnResolutionFlow = newSsnResolutionFlow === 'on';
  const VerificationComponent = getVerificationComponent(verification);
  const submitFunction = submitVerification(verification.object);
  if (isNewSsnResolutionFlow) {
    return (
      <Auth isVerification>
        <VerificationComponent onSubmit={submitFunction} newSsnResolutionFlow />
      </Auth>
    );
  }
  return <VerificationComponent onSubmit={submitFunction} />;
};

const mapStateToProps = (state: any, props: any) => {
  const { verifications } = state;

  return {
    verifications,
    verificationError: verifications?.verification?.error
      ? verifications.verification.errors
      : '',
  };
};

let actions = {
  fetchVerification: fetchVerificationAction,
  submitEducationVerification: submitEducationVerificationAction,
  submitEmploymentVerification: submitEmploymentVerificationAction,
  submitSSNVerificationWithoutDocs: submitSSNVerificationWithoutDocsAction,
  submitVerificationDefault: submitVerificationDefaultAction,
  submitMatrixVerification: submitMatrixVerificationAction,
};

if (ENV === 'development') {
  if (window?.location?.search?.match(/mock=driver_license_history/)) {
    actions = {
      ...actions,
      fetchVerification: fetchMockVerificationAction(
        'driver_license_history',
      ) as unknown as DispatchVerification,
    };
  } else if (window?.location?.search?.match(/mock=driver_license/)) {
    actions = {
      ...actions,
      fetchVerification: fetchMockVerificationAction(
        'driver_license',
      ) as unknown as DispatchVerification,
    };
  }
}

export default connect(mapStateToProps, actions)(Status);
