import { AxiosError, AxiosResponse } from 'axios';
import { camelizeKeys } from 'humps';
import { QueryKey, useQuery } from '@tanstack/react-query';
import { FetchReportParams, ReportReponse } from 'api/verifications/types';
import { fetchReport } from '../verifications/client';
import setReportStatusfromResult from './lib/setReportStatusFromResult';

export const useReport = ({
  id,
  token,
  contactCompanyForReport = false,
}: FetchReportParams) => {
  const key: QueryKey = ['candidate/reports', { id }];

  const request = async () => {
    if (!contactCompanyForReport) {
      return fetchReport({
        id,
        token,
      });
    }
    // Return a resolved promise with an empty response or handle it as needed
    return Promise.resolve({
      data: { report: null },
    } as AxiosResponse<ReportReponse>);
  };

  const { isLoading, isError, data, error } = useQuery<
    AxiosResponse<ReportReponse>,
    AxiosError
  >({
    queryKey: key,
    queryFn: request,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: Boolean(token),
  });

  const report = data?.data?.report
    ? setReportStatusfromResult(
        camelizeKeys(data.data.report) as Record<string, any>,
      )
    : null;

  return {
    report,
    isLoading,
    isError,
    error,
  };
};
