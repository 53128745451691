import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import {
  allSearchRecords,
  getSearchesBySectionAndResult,
} from 'components/Reports/Report/lib/screenings';
import { Report } from 'types/report';
import { CANDIDATE_STORY_SECTIONS } from '../../constants';
import Record from './record';

type Props = {
  report: Report;
};

const RecordsContainer = styled.div`
  margin: 35px 0;
  display: flex;
  flex-wrap: wrap;
`;

const Records: React.FC<Props> = ({ report }) => {
  const {
    candidate: { dob },
  } = report;

  const considerSearches = getSearchesBySectionAndResult(
    report,
    CANDIDATE_STORY_SECTIONS,
    'consider',
  );

  const records = considerSearches.map(search => {
    return allSearchRecords(search).map((record: any) => (
      <Record key={record.id} dob={dob} record={record} />
    ));
  });

  return (
    <M.Grid data-testid='candidate-stories-records-container'>
      <M.GridRow>
        <RecordsContainer>{records}</RecordsContainer>
      </M.GridRow>
    </M.Grid>
  );
};

export default Records;
