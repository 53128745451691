import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { Report } from 'types/report';
import { Link } from 'react-router-dom';
import StepContainer from './stepContainer';
import Records from './records';

type Props = {
  report: Report;
  handleNext: () => void;
};

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;

const StyledLink = styled(Link)`
  margin-right: 10px;
`;

const ReviewRecordsSection: React.FC<Props> = ({ report, handleNext }) => {
  const {
    account: { name },
  } = report;

  const title = 'Review your record';
  const description = (
    <>
      <p>
        You have an opportunity to share more beyond what&apos;s in your
        records. {name} is interested in learning more about you.
      </p>
      <p>Before you continue, confirm whether these records are correct.</p>
    </>
  );

  return (
    <StepContainer report={report} title={title} description={description}>
      <Records report={report} />
      <div>
        <h5>Does this look right?</h5>
        <p className='p2'>If you found errors let us know.</p>
        <ButtonContainer>
          <StyledLink to={`/reports/${report.id}#file-a-dispute-card`}>
            <M.Button kind='secondary'>
              No, file a dispute
              <M.Icon icon='ArrowUpRight' />
            </M.Button>
          </StyledLink>
          <M.Button kind='primary' onClick={handleNext}>
            Yes, this is correct
          </M.Button>
        </ButtonContainer>
      </div>
    </StepContainer>
  );
};

export default ReviewRecordsSection;
