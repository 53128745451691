import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { i18n } from '@international/mastodon-i18n';

import { Report } from 'types/report';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { canEnableCandidateStories } from 'lib/report/candidateStories';
import UpdatePersonalInfoWorkflowCard from './PersonalInfo/UpdatePersonalInfoWorkflowCard';
import {
  setCandidateStoriesEnabledFromSetting as setCandidateStoriesEnabledFromSettingAction,
  setCandidateStoriesGeneralInfoOnlyFromSetting as setCandidateStoriesGeneralInfoOnlyFromSettingAction,
  setCandidateStoriesSubmissionStatus as setCandidateStoriesSubmissionStatusAction,
} from '../../../actions';

import ReportAnErrorWorkflowCard from './Disputes/ReportAnErrorWorkflowCard';
import CandidateStoryWorkflowCard from './CandidateStories/CandidateStoryWorkflowCard';
import { isReportEligibleForUpdateDL } from './lib/mvr';

export interface CandidateActionWorkflowCardsProps {
  candidateStoriesEnabled: boolean;
  candidateStoriesGeneralInfoOnly: boolean;
  candidateStorySubmitted: boolean;
  disputeSubmitted: boolean;
  report: Report;
  setCandidateStoriesEnabledFromSetting: any;
  setCandidateStoriesSubmissionStatus: any;
  setCandidateStoriesGeneralInfoOnlyFromSetting?: any;
  stories?: any;
  submitError?: any;
}

interface State {
  disputeModalOpen: boolean;
  newDisputeModalOpen: boolean;
  candidateStoryOpen: boolean;
  updateDLModalOpen: boolean;
  isSuccessDisputeOpenedOnce: boolean;
  confirmPreviouslyDisputedReportModalOpen: boolean;
}

class CandidateActionWorkflowCards extends Component<
  CandidateActionWorkflowCardsProps,
  State
> {
  constructor(props: CandidateActionWorkflowCardsProps) {
    super(props);

    this.state = {
      disputeModalOpen: false,
      newDisputeModalOpen: false,
      candidateStoryOpen: false,
      updateDLModalOpen: false,
      isSuccessDisputeOpenedOnce: false,
      confirmPreviouslyDisputedReportModalOpen: false,
    };

    this.toggleDisputeModal = this.toggleDisputeModal.bind(this);
    this.toggleNewDisputeModal = this.toggleNewDisputeModal.bind(this);
    this.toggleCandidateStoryModal = this.toggleCandidateStoryModal.bind(this);
    this.toggleUpdateDLModal = this.toggleUpdateDLModal.bind(this);
    this.openSuccessDisputeModal = this.openSuccessDisputeModal.bind(this);
    this.toggleConfirmPreviouslyDisputedReportModal =
      this.toggleConfirmPreviouslyDisputedReportModal.bind(this);
  }

  componentDidMount() {
    const {
      setCandidateStoriesSubmissionStatus,
      setCandidateStoriesGeneralInfoOnlyFromSetting,
      report,
    } = this.props;
    const { account } = report;

    this.setCandidateStoriesEnabled();

    if (account) {
      setCandidateStoriesGeneralInfoOnlyFromSetting(false);
    }
    setCandidateStoriesSubmissionStatus(!!report.candidateStories.length);
  }

  setCandidateStoriesEnabled() {
    const { report, setCandidateStoriesEnabledFromSetting } = this.props;
    const enabled = canEnableCandidateStories(report);

    setCandidateStoriesEnabledFromSetting(enabled);
  }

  toggleDisputeModal() {
    // tag this as a dispute flow
    (window as any).hj &&
      (window as any).hj('tagRecording', ['clicked_dispute_button']);
    this.setState(prevState => ({
      disputeModalOpen: !prevState.disputeModalOpen,
    }));
  }

  toggleNewDisputeModal() {
    this.setState(prevState => ({
      newDisputeModalOpen: !prevState.newDisputeModalOpen,
    }));
  }

  toggleCandidateStoryModal() {
    this.setState(prevState => ({
      candidateStoryOpen: !prevState.candidateStoryOpen,
    }));
  }

  toggleUpdateDLModal() {
    this.setState(prevState => ({
      updateDLModalOpen: !prevState.updateDLModalOpen,
    }));
  }

  openSuccessDisputeModal() {
    this.setState(prevState => ({
      isSuccessDisputeOpenedOnce: !prevState.isSuccessDisputeOpenedOnce,
      disputeModalOpen: !prevState.disputeModalOpen,
    }));
  }

  toggleConfirmPreviouslyDisputedReportModal() {
    this.setState(prevState => ({
      confirmPreviouslyDisputedReportModalOpen:
        !prevState.confirmPreviouslyDisputedReportModalOpen,
    }));
  }

  render() {
    const {
      report,
      disputeSubmitted,
      candidateStoriesEnabled,
      candidateStoriesGeneralInfoOnly,
      candidateStorySubmitted,
      submitError,
      stories,
    } = this.props;

    const {
      disputeModalOpen,
      candidateStoryOpen,
      newDisputeModalOpen,
      updateDLModalOpen,
      isSuccessDisputeOpenedOnce,
      confirmPreviouslyDisputedReportModalOpen,
    } = this.state;
    const RenderCandidateStoryWorkflowCard = (
      <>
        {candidateStoriesEnabled && (
          <CandidateStoryWorkflowCard
            toggleDisputeModal={this.toggleDisputeModal}
            stories={stories}
            report={report}
            candidateStorySubmitted={candidateStorySubmitted}
            candidateStoriesGeneralInfoOnly={candidateStoriesGeneralInfoOnly}
            toggleCandidateStoryModal={this.toggleCandidateStoryModal}
            isOpen={candidateStoryOpen}
            submitError={submitError}
          />
        )}
      </>
    );

    const RenderReportAnErrorWorkflowCard = (
      <ReportAnErrorWorkflowCard
        candidateStoriesEnabled={candidateStoriesEnabled}
        disputeSubmitted={disputeSubmitted}
        isOpen={disputeModalOpen}
        isNewOpen={newDisputeModalOpen}
        isCPDOpen={confirmPreviouslyDisputedReportModalOpen}
        isSuccessDisputeOpenedOnce={isSuccessDisputeOpenedOnce}
        report={report}
        toggleCandidateStoryModal={this.toggleCandidateStoryModal}
        toggleDisputeModal={this.toggleDisputeModal}
        toggleNewDisputeModal={this.toggleNewDisputeModal}
        toggleUpdateDLModal={this.toggleUpdateDLModal}
        openSuccessDisputeModal={this.openSuccessDisputeModal}
        toggleConfirmPreviouslyDisputedReportModal={
          this.toggleConfirmPreviouslyDisputedReportModal
        }
      />
    );

    return (
      <div
        role='list'
        aria-label={i18n.getStr('component.Report.CandidateActions.title')}
      >
        {isReportEligibleForUpdateDL(report) && (
          <UpdatePersonalInfoWorkflowCard
            report={report}
            isDialogOpen={updateDLModalOpen}
            toggleShowModal={this.toggleUpdateDLModal}
          />
        )}

        {RenderCandidateStoryWorkflowCard}
        {RenderReportAnErrorWorkflowCard}
      </div>
    );
  }

  static propTypes = {
    setCandidateStoriesEnabledFromSetting: PropTypes.func.isRequired,
    setCandidateStoriesGeneralInfoOnlyFromSetting: PropTypes.func.isRequired,
    setCandidateStoriesSubmissionStatus: PropTypes.func.isRequired,
    candidateStoriesEnabled: PropTypes.bool,
    candidateStoriesGeneralInfoOnly: PropTypes.bool,
    candidateStorySubmitted: PropTypes.bool.isRequired,
    disputeSubmitted: PropTypes.bool.isRequired,
    report: PropTypes.object.isRequired,
    submitError: PropTypes.bool.isRequired,
    stories: PropTypes.array.isRequired,
  };

  static defaultProps = {
    candidateStoriesEnabled: false,
    candidateStoriesGeneralInfoOnly: false,
  };
}

const mapStateToProps = (
  state: any,
  props: CandidateActionWorkflowCardsProps,
) => ({
  candidateStoriesEnabled: !!state.candidateStories.candidateStoriesEnabled,
  candidateStoriesGeneralInfoOnly:
    !!state.candidateStories.candidateStoriesGeneralInfoOnly,
  candidateStorySubmitted: !!state.candidateStories.candidateStorySubmitted,
  disputeSubmitted: !!state.portal.disputeSubmitted,
  stories:
    state.candidateStories?.stories || props.report.candidateStories || [],
  submitError: !!state.candidateStories.submitError,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  setCandidateStoriesEnabledFromSetting: (enabled: boolean) =>
    dispatch(setCandidateStoriesEnabledFromSettingAction(enabled)),
  setCandidateStoriesGeneralInfoOnlyFromSetting: (enabled: boolean) =>
    dispatch(setCandidateStoriesGeneralInfoOnlyFromSettingAction(enabled)),
  setCandidateStoriesSubmissionStatus: (status: any) =>
    dispatch(setCandidateStoriesSubmissionStatusAction(status)),
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(CandidateActionWorkflowCards);
