import React from 'react';
import { Report } from 'types/report';
import Loading from 'components/Loading';
import CandidateStoriesContainer from 'components/CandidateStories/container';
import { canEnableCandidateStories } from 'lib/report/candidateStories';
import { useGetFlagByReportAccount } from 'lib/flag/getFlag';
import NotFound from 'routes/pages/NotFound';
import { FLAGR_KEYS } from '../../../constants';

type Props = {
  report: Report;
};

const CandidateStoriesPage: React.FC<Props> = ({ report }) => {
  // TODO: [EADJU-17] Clean up candidateStoryV2Parity flag
  const { flag, isError, isFetched } = useGetFlagByReportAccount(
    FLAGR_KEYS.candidateStoryV2Parity,
    report,
  );

  const flagDisabled = isFetched && flag.variantKey !== 'on';

  const storiesEnabledForCandidate = canEnableCandidateStories(report);

  if (flagDisabled || !storiesEnabledForCandidate || isError) {
    return <NotFound />;
  }

  return (
    <div data-testid='candidate-stories-page'>
      {isFetched ? <CandidateStoriesContainer report={report} /> : <Loading />}
    </div>
  );
};

export default CandidateStoriesPage;
